<template>
  <div
    class="container"
    :style="containerStyle"
  >
    <h1 :style="textStyle">{{ tr('Unknown Tool') }}</h1>
    <p :style="textStyle">{{ tr('This error should not happen. Please contact dātma for assistance.') }}</p>
  </div>
</template>

<script>
// Copyright (C) Omics Data Automation, Inc. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited
// Proprietary and confidential

import { computed, inject } from 'vue'
import { useStore } from 'vuex'

import { themeColors } from '@/common/shared.js'

export default {
  setup() {
    const store = useStore()

    const containerStyle = computed(() => {
      return {
        backgroundColor: themeColors[store.getters.currentThemeName].errorBackgroundColor,
      }
    })

    const textStyle = computed(() => {
      return {
        color: themeColors[store.getters.currentThemeName].errorTextColor,
      }
    })

    const tr = inject('tr')

    return {
      containerStyle,
      textStyle,
      tr,
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: static;
  width: 100%;
  height: 100%;
}

h1 {
  margin: 0;
  padding: 1rem;
}
</style>
